






































































































































































































































































/* eslint-disable */
import { Vue, Component, Watch } from "vue-property-decorator";
import { api_group, create_group} from "@/api";
import { More } from "@/mixin/more";
import { OtherModule } from "@/store/other";
import { UserModule } from "@/store/user";
import { ElForm } from "element-ui/types/form";
import { Encryption } from "@/util/encryption";
interface user {
  id: number;
  value: string;
  username: string;
}
@Component
export default class extends More(api_group.get_group) {
  step = false;
  validateName(rules: any, value: any, callback: any) {
    if (value === "") {
      callback(new Error("상대에게 노출될 방 이름을 입력해주세요."));
    } else if (this.older.name !== value) {
      callback(new Error("방 이름 중복확인을 진행해주세요."));
    } else {
      callback();
    }
  }
  async check_name() {
    this.btn_loadding.name = true;
    (this.$refs["form"] as ElForm).validateField("name", async (err_str) => {
      if (err_str === "방 이름 중복확인을 진행해주세요." || err_str === "") {
        const data = await api_group
          .check_group_name({ name: this.info.name })
          .finally(() => {
            this.btn_loadding.name = false;
          });
        this.older.name = this.info.name;
        await (this.$refs["form"] as ElForm).validateField("name");
        this.$message.success("중복된 방이름이 없습니다.");
      } else {
        this.$message.error(err_str);
        this.btn_loadding.name = false;
      }
    });
  }
  rules = {
    name: [
      {
        required: true,
        validator: this.validateName,
        trigger: "change",
      },
    ],
    order: [{ required: true, message: "문제풀이 순서" }],
    timer: [{ required: true, message: "문제풀이 시간" }],
    chapter: [{ required: true, message: "섹션명을 선택해주세요" }],
  };
  select_user: user[] = [];
  restaurants: user[] = [];
  state = "";
  now: any = {};
  timeout: any = null;
  older:{name:string | undefined} = {
    name: "",
  };
  info: create_group = {
    type: 2,
    name: "",
    order: 1,
    timer: 7,
    userlist: "",
    subject: "",
    chapter: "",
  };

  subject = []

  options = []

  btn_loadding = {
    name: false,
  };

  filter = {
    type: 2,
  };

  async querySearchAsync(queryString: string, cb: any) {
    var restaurants: any = this.restaurants;
    var results = queryString
      ? restaurants.filter(this.createStateFilter(queryString))
      : restaurants;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      cb(results);
    }, 3000 * Math.random());
  }
  createStateFilter(queryString: string) {
    return (state: any) => {
      return (
        state.username.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      );
    };
  }

  async show_step(){
    this.subject = await api_group.get_user_course()
    this.step = true
  }

  async add_user() {
    let user = await api_group.search_user({ username: this.state })
    if(user.length === 1){
      this.now = user[0]
    }else{
      this.$message.error("해당 사용자가 존재하지 않습니다.")
      return
    }
    if (!this.now.id || this.select_user.length >=3) {
      this.$message.error("초대할 사용자의 아이디를 입력해주세요.")
      return
    }
    let index = this.select_user.findIndex((v) => {
      return v.id === this.now.id;
    });
    if (index === -1 && this.user_info?.id !== this.now.id) {
      this.select_user.push(this.now);
    }
    this.now = {};
    this.state = "";
  }

  reduce_user(id: number) {
    let index = this.select_user.findIndex((v) => v.id === id);
    this.select_user.splice(index, 1);
  }

  handleSelect(item: user) {
    this.now = item;
  }

  to_check_user(id:number){
    this.$router.push(`/group/user/${Encryption.base_enc(id)}/`)
  }

  @Watch('info.subject',{deep:true})
  watch_subject(res:number){
    this.info.subject = res
    this.info.chapter = ''
    this.subject.forEach((item:any)=>{
      if(item.id === res){
        this.options = item.chapter
      }
    })
  }

  @Watch("select_user")
  watch_select_user(res: any) {
    let id_arr: number[] = [];
    res.forEach((item: user) => {
      id_arr.push(item.id);
    });
    this.info.userlist = id_arr.join(",");
  }

  async submit() {
    try {
      await (this.$refs["form"] as ElForm).validate();
      this._loading = true;
      const res:any = await api_group
        .create_group(this.info as any as create_group)
        .finally(() => {
          this._loading = false;
        });
        this.$router.push(`/group/user/${Encryption.base_enc(res.id)}/`)
    } catch (e) {
      this.$message.error(
        "입력하신 정보가 정확하지 않습니다. 다시 한번 확인해 주세요."
      );
    }
  }

  async created() {
    // let restaurants = await api_group.search_user({ search: "" });
    // restaurants.forEach((e: any) => {
    //   this.restaurants.push({
    //     value: e.username,
    //     ...e,
    //   });
    // });
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }

  get user_info() {
    return UserModule.info;
  }

}
